// Greenhouse API: https://developers.greenhouse.io/job-board.html
// Greenhouse display docs: https://s101.recruiting.eu.greenhouse.io/jobboard/integration/documentation/api_board_hosted_apps

import React, { useState, useEffect, memo } from 'react';
import JobsByDepartment from './JobsByDepartment';

const JobListings = () => {
  const euJobBoardURL = 'https://boards-api.greenhouse.io/v1/boards/ably30/departments';
  const usJobBoardURL = 'https://boards-api.greenhouse.io/v1/boards/ablyus/departments';
  const [departments, setDepartments] = useState([]);

  const getAllJobs = async () => {
    try {
      const [euData, usData] = await Promise.all([fetch(euJobBoardURL), fetch(usJobBoardURL)]);
      const euJobs = await euData.json();
      const usJobs = await usData.json();

      const allJobs = euJobs.departments.map(department => {
        const departmentInUs = usJobs.departments.find(usDepartment => department.name === usDepartment.name);
        return {
          ...department,
          jobs: [...department.jobs, ...departmentInUs.jobs],
        };
      });

      setDepartments([allJobs]);
    } catch (e) {
      setDepartments([]);
    }
  };

  useEffect(() => {
    getAllJobs();
  }, []);

  if (!departments.length || departments.every(department => department.jobs?.length === 0)) {
    return (
      <div className="bg-white p-40 ui-text-p1">We do not have any open jobs at the moment, but check back soon.</div>
    );
  }

  return (
    <div className="bg-white py-40">
      {departments[0].map((department, i) => (
        <JobsByDepartment department={department} key={department.id + i} />
      ))}
    </div>
  );
};

export default memo(JobListings);
