import Slider from '@ably/ui/core/Slider/component';

import bindOurValuesTouchEvents from './careers/bind-our-values-touch-events';
import './careers/index.css';
import './careers/job-listings';
import { animateStatBar, animateStatMotif } from './careers/animations';

document.addEventListener('DOMContentLoaded', () => {
  const sliders = document.querySelectorAll('[data-id="slider"]');

  Slider({
    container: sliders[0],
    mqEnableThreshold: () => !window.matchMedia('(min-width: 65rem)').matches,
  });

  Slider({
    container: sliders[1],
    mqEnableThreshold: () => !window.matchMedia('(min-width: 65rem)').matches,
  });

  bindOurValuesTouchEvents();

  animateStatBar();
  animateStatMotif();
});
