import * as basicScroll from 'basicscroll';

const animateStatBar = () => {
  const bar = document.querySelector('[data-id="yoy-stat-bar"]');

  if (!bar) return;

  const barsWidth = basicScroll.create({
    elem: bar,
    from: 'top-bottom',
    to: 'middle-middle',
    direct: true,
    props: {
      '--ws-yoy-stat-bar': {
        from: '1%',
        to: '96%',
      },
    },
  });

  barsWidth.start();
};

const animateStatMotif = () => {
  const motif = document.querySelector('[data-id="yoy-stat-motif"]');

  if (!motif) return;

  const scale = basicScroll.create({
    elem: motif,
    from: 'top-bottom',
    to: 'bottom-top',
    direct: true,
    props: {
      '--yoy-stat-mofif-scale': {
        from: 0.75,
        to: 1.25,
      },
    },
  });
  scale.start();

  const translate = basicScroll.create({
    elem: motif,
    from: 'top-bottom',
    to: 'bottom-top',
    direct: true,
    props: {
      '--yoy-stat-mofif-translate': {
        from: '0%',
        to: '40%',
      },
    },
  });
  translate.start();
};

export { animateStatMotif, animateStatBar };
