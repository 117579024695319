import React from 'react';
import T from 'prop-types';
import Icon from '@ably/ui/core/Icon';

const Job = ({ id, absoluteUrl, title, location }) => {
  return (
    <div key={id}>
      <a href={absoluteUrl} className="ui-featured-link text-p2 py-8">
        <div className="text-charcoal-grey hover:text-active-orange">{title}</div>

        <div className="ui-text-p3 flex text-dark-grey">
          <Icon name="icon-display-map-pin" size="1.5rem" color="text-dark-grey" />
          {location}
        </div>
      </a>
    </div>
  );
};

Job.propTypes = {
  id: T.number,
  absoluteUrl: T.string,
  title: T.string,
  location: T.string,
};
export default Job;
