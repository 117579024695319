// This file is loaded only on the new layout and needs to be loaded after the ably-ui one.
// It won't work without it as it expects the store on components shared between the hybrid and new layouts to
// be already initiated (Meganav, Footer, sprites injection).

// Adapter for rails
import Rails from '@rails/ujs';
import initSentry from './sentry';

document.addEventListener('DOMContentLoaded', () => {
  Rails.start();

  // See ReferralAttributionConcern
  // This ensures that a tracking code such as #src.[campaign] is stored in the relevant cookied
  // by issuing an XHR request to the Rails app with the campaign ID
  const hash = window.location.hash.substr(1);

  if (hash.indexOf('src.') > -1) {
    const referralCode = hash.split('.')[1];

    if (referralCode) {
      fetch(`/noop?src=${encodeURI(referralCode)}`);
    }
  }

  const { sentryBrowserConfig } = document.body.dataset;
  if (sentryBrowserConfig) initSentry(sentryBrowserConfig);
});
