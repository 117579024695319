import React from 'react';
import T from 'prop-types';
import Job from './Job';

const JobsByDepartment = ({ department }) => {
  if (!department.jobs?.length) {
    return null;
  }
  return (
    <div className="bg-white pt-8 sm:pt-16 px-32">
      <h3 className="text-dark-grey ui-text-overline1 mt-12">{department.name}</h3>
      {department.jobs.map(job => (
        <Job key={job.id} absoluteUrl={job.absolute_url} title={job.title} location={job.location.name} />
      ))}
    </div>
  );
};

JobsByDepartment.propTypes = {
  department: T.object,
};

export default JobsByDepartment;
